import React, { useEffect, useState } from 'react';

import BBSocialMediaSideShareDesktop from './BBSocialMediaSideShareDesktop';
import BBSocialMediaSideShareMobile from './BBSocialMediaSideShareMobile';
import BBSocialMediaSideSharePopup from './BBSocialMediaSideSharePopup';
import {
  PTBBSocialMediaSideShare,
  PTBBSocialMediaSideShareDefaults,
} from './PTBBSocialMediaSideShare';

import IconFacebook from '../../../../public/static/icons/social/social-media-facebook.svg';
import IconTwitter from '../../../../public/static/icons/social/social-media-twitter.svg';
import IconLinkedIn from '../../../../public/static/icons/social/social-media-linkedin.svg';
import IconDirectLink from '../../../../public/static/icons/social/social-media-url.svg';

import styles from './index.module.scss';

const BBSocialMediaSideShare = ({
  popupContents = PTBBSocialMediaSideShareDefaults.popupContents,
  activeProviders,
  hasMobileBorders = PTBBSocialMediaSideShareDefaults.hasMobileBorders,
  isDesktopSticky = PTBBSocialMediaSideShareDefaults.isDesktopSticky,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('');

  const referrers = {
    facebook: {
      icon: <IconFacebook />,
      baseURL: 'https://www.facebook.com/sharer/sharer.php?u=',
      target: '_blank',
      alt: 'Share the page on Facebook',
    },
    twitter: {
      icon: <IconTwitter />,
      baseURL: 'https://twitter.com/share?url=',
      target: '_blank',
      alt: 'Share the page on Twitter',
    },
    linkedin: {
      icon: <IconLinkedIn />,
      baseURL: 'https://www.linkedin.com/shareArticle?mini=true&url=',
      target: '_blank',
      alt: 'Share the page on LinkedIn',
    },
    link: {
      icon: <IconDirectLink />,
      alt: 'Share the page',
    },
  };

  useEffect(() => {
    if (window && window.location) {
      setCurrentLocation(window.location.href);
    }
  }, []);

  const onPopupToggle = (event) => {
    event.preventDefault();
    setPopupOpen(!popupOpen);
  };

  return (
    <div className={`bb ${styles['bb-social-media-side-share']}`}>
      <BBSocialMediaSideShareMobile
        currentLocation={currentLocation}
        referrers={referrers}
        activeProviders={activeProviders}
        hasMobileBorders={hasMobileBorders}
        onPopupToggle={onPopupToggle}
      />

      <BBSocialMediaSideShareDesktop
        currentLocation={currentLocation}
        activeProviders={activeProviders}
        referrers={referrers}
        isDesktopSticky={isDesktopSticky}
        onPopupToggle={onPopupToggle}
      />

      {popupOpen && (
        <BBSocialMediaSideSharePopup
          {...popupContents}
          url={currentLocation}
          onToggle={onPopupToggle}
        />
      )}
    </div>
  );
};

BBSocialMediaSideShare.propTypes = PTBBSocialMediaSideShare;

export default BBSocialMediaSideShare;
