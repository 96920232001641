import PropTypes from 'prop-types';

const PTBBSocialMediaSideShare = {
  activeProviders: PropTypes.arrayOf(PropTypes.string).isRequired,
  popupContents: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  hasMobileBorders: PropTypes.bool,
  isDesktopSticky: PropTypes.bool,
};

const PTBBSocialMediaSideShareDefaults = {
  popupContents: {
    title: 'Share your Concern',
    description:
      'Help us spread the word on tackling hunger and transform lives for the world’s poorest.',
  },
  hasMobileBorders: false,
  isDesktopSticky: false,
};

const PTBBSocialMediaSideShareDisplayContents = PropTypes.shape({
  icon: PropTypes.element.isRequired,
  popupOpen: PropTypes.bool,
  baseURL: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
});

const PTBBSocialMediaSideShareDisplay = {
  facebook: PTBBSocialMediaSideShareDisplayContents,
  twitter: PTBBSocialMediaSideShareDisplayContents,
  linkedin: PTBBSocialMediaSideShareDisplayContents,
  link: PTBBSocialMediaSideShareDisplayContents,
};

export {
  PTBBSocialMediaSideShare,
  PTBBSocialMediaSideShareDefaults,
  PTBBSocialMediaSideShareDisplay,
};
