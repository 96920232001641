import React from 'react';
import PropTypes from 'prop-types';

import {
  PTBBSocialMediaSideShare,
  PTBBSocialMediaSideShareDisplay,
} from '../PTBBSocialMediaSideShare';

import styles from './index.module.scss';

const BBSocialMediaSideShareDesktop = ({
  activeProviders,
  referrers,
  onPopupToggle,
  currentLocation,
  isDesktopSticky,
}) => (
  <div
    data-component={'bb-social-media-side-share-desktop'}
    className={`${styles['bb-social-media-side-share-desktop']} ${isDesktopSticky ? styles['bb-social-media-side-share-desktop--sticky'] : ''}`}
  >
    <mark>Share</mark>

    {activeProviders.map((provider) => (
      <a
        key={provider}
        className={styles['bb-social-media-side-share-desktop__provider-link']}
        href={`${
          referrers[provider].baseURL
            ? encodeURI(referrers[provider].baseURL + currentLocation)
            : '#'
        }`}
        target={referrers[provider].target}
        aria-label={referrers[provider].alt}
        onClick={referrers[provider].baseURL ? null : onPopupToggle}
      >
        {referrers[provider].icon}
      </a>
    ))}
  </div>
);

BBSocialMediaSideShareDesktop.propTypes = {
  ...PTBBSocialMediaSideShare,
  currentLocation: PropTypes.string.isRequired,
  referrers: PropTypes.shape(PTBBSocialMediaSideShareDisplay).isRequired,
};

export default BBSocialMediaSideShareDesktop;
