import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import copy from 'copy-to-clipboard';

import { TextFieldPill } from '../../../../01_atoms/FormElements';
import Button from '../../../../01_atoms/Button';
import Popup from '../../../../02_molecules/Popup';
import withIE, { IEContext } from '../../../../05_hoc/withIE';

import styles from './index.module.scss';

const BBSocialMediaSideSharePopup = ({ title, description = null, onToggle, url = '' }) => {
  const [copiedStatus, setCopiedStatus] = useState('Copy link');

  const onCopyToClipboard = () => {
    copy(url, {
      format: 'text/plain',
      onCopy: () => {
        setCopiedStatus('Link copied!');
      },
    });
  };

  return (
    <Popup isVisible onPopupClose={onToggle} className={styles['popup-social-media-side-share']}>
      <IEContext.Consumer>
        {({ isIE }) => (
          <div
            className={`${styles['popup-social-media-side-share__contents']} ${isIE ? styles['popup-social-media-side-share__contents--ie11-compat'] : ''}`}
            data-component={'popup-social-media-side-share'}
          >
            <div
              className={styles['popup-social-media-side-share__contents-close-popup']}
              onKeyDown={onToggle}
              onClick={onToggle}
            />

            <div className={styles['popup-social-media-side-share__contents-title']}>{title}</div>

            <p>{description}</p>

            <h6>Direct link</h6>

            <Form
              onSubmit={() => {}}
              initialValues={{ url }}
              render={({ handleSubmit }) => (
                <form
                  className={styles['popup-social-media-side-share__contents-form']}
                  onSubmit={handleSubmit}
                >
                  <TextFieldPill component="input" type="text" name="url" disabled />
                  <Button
                    type="secondary"
                    isMobileBlock
                    className="popup-social-media-side-share--button"
                    onClick={onCopyToClipboard}
                  >
                    {copiedStatus}
                  </Button>
                </form>
              )}
            />
          </div>
        )}
      </IEContext.Consumer>
    </Popup>
  );
};

BBSocialMediaSideSharePopup.propTypes = {
  title: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
};

export default withIE(BBSocialMediaSideSharePopup);
